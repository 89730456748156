import Button from "./components/core/Button/Button";

const Layout = () => {
  return (
    <div>
      <Button />
    </div>
  );
};

export default Layout;
